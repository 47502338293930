import * as React from "react"
import Footer from "../Footer/Footer"
import OnlyLogo from "../common/OnlyLogo"
import "./Login.scss"
import TitleWithSeparator from "../common/TitleWithSeparator"
import { images, variables } from "../../data/variables"
import { emailValidation } from "../../lib/tools"
import Cookies from "js-cookie"
import LoadingSpinner from "../common/LoadingSpinner"
import { store } from "../../translations/store"
import { observer } from "mobx-react-lite"

const LoginComponent = observer(() => {
  const [username, setUsername] = React.useState<string>("")
  const [password, setPassword] = React.useState<string>("")
  const [errorCode, setErrorCode] = React.useState(null)
  const [success, setSuccess] = React.useState(false)
  const [loading, setLoading] = React.useState<boolean>(false)

  // if (store.token)
  // {
  //     location.href = 'https://evrotom.highlandesigns.com/shop';
  // }
  const submit = () => {
    if (loading) {
      return
    }
    if (!username.length) {
      setErrorCode(991)
      return
    }
    if (!password.length) {
      setErrorCode(992)
      return
    }
    setErrorCode(null)
    setLoading(true)
    const input = { password }
    const userId = emailValidation(username)
      ? { email: username }
      : { username }
    Object.assign(input, userId)
    fetch("https://evrotom.highlandesigns.com/shop" + variables.loginRoute, {
      method: "POST",
      body: JSON.stringify(input),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(async res => {
        const data = await res.json()
        console.log(data)
        const {
          data: { jwt, errorCode },
        } = data
        if (jwt) {
          Cookies.set("cEvrtT", jwt)
          store.setToken(jwt)
          fetch(
            `https://evrotom.highlandesigns.com/shop/?rest_route=/simple-jwt-login/v1/autologin&JWT=${jwt}`,
            {
              method: "GET",
            }
          )
            .then(res => res.json())
            .then(d => {
              const w = typeof window !== "undefined" ? window : null
              console.log(d)
              console.log(res)
              setSuccess(true)
              setLoading(false)
              setTimeout(
                () =>
                  (w.location.href =
                    "https://evrotom.highlandesigns.com/shop/"),
                2000
              )
            })
        } else {
          if (errorCode) {
            setErrorCode(errorCode)
            setLoading(false)
          }
        }
      })
      .catch(err => console.log(err))
  }

  const handleEnter = e => {
    if (e.keyCode === 13) {
      submit()
    }
  }

  return (
    <div className="login-page">
      <div className="inner w-90">
        <OnlyLogo />
        <div className="content">
          <TitleWithSeparator text={"Moj nalog"} />
          <div className="image d-flex">
            <div className="image-container">
              <img src={images.beekeeper} alt="beekeper" />
            </div>
          </div>
          <div className="form">
            <div className="inner">
              <div className="name d-flex align-items-center">
                <div className="icon">
                  <img src={images.person} />
                </div>
                <div className="input-container">
                  <input
                    type="text"
                    value={username}
                    onKeyDown={handleEnter}
                    onChange={e => setUsername(e.target.value)}
                    placeholder="Username or Email"
                  />
                </div>
              </div>
              <div className="password d-flex align-items-center mt-2">
                <div className="icon">
                  <img src={images.lock} />
                </div>
                <div className="input-container">
                  <input
                    type="password"
                    value={password}
                    onKeyDown={handleEnter}
                    onChange={e => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                </div>
              </div>
            </div>
            <div className="status mt-2 d-flex justify-content-center">
              {success && (
                <div className="d-flex justify-content-center align-items-center">
                  <svg
                    className="checkmark__login"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                  <span className="ml-1">Uspešno ste se ulogovali</span>
                </div>
              )}
              {loading && <LoadingSpinner />}
              {errorCode && (
                <span className="">
                  {errorCodes()[errorCode] || "Server error"}
                </span>
              )}
            </div>
            <button className="button" onClick={submit} disabled={loading}>
              LOGIN
            </button>
            <div className="forgot-password d-flex">
              <span>Forgot Password?</span> <a href="/">Click Here</a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
})

export default LoginComponent

function errorCodes() {
  return {
    48: "Ime ili lozinka su netacni",
    991: "Username/email polje ne sme biti prazno",
    992: "Sifra ne sme biti prazna",
  }
}
