import { Link } from "gatsby"
import { observer } from "mobx-react-lite"
import React from "react"
import { images } from "../../data/variables"

const OnlyLogo = observer(() => {
  return (
    <div className="header">
      <div className="logo">
        <Link to="/">
          <img src={images.logo} alt="Evrotom" />
        </Link>
      </div>
    </div>
  )
})

export default OnlyLogo
