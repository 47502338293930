import * as React from "react"
import { Router } from "@reach/router"
import { store } from "../translations/store"
import { observer } from "mobx-react-lite"
import LoginComponent from "../components/Login/login"

const Login = observer(() => (
  <Router>
    <PrivateRoute
      path="/login"
      component={LoginComponent}
      {...null}
      token={store.token}
    />
  </Router>
))

export default Login

const PrivateRoute = ({ component: Component, location, token, ...rest }) => {
  if (token) {
    //   window.location.href = 'https://evrotom.highlandesigns.com/shop/';
  }

  // return token ? null : <Component {...rest} />

  return <Component {...rest} />
}
